<template>
  <div class="formulary-picker">
    <div class="flex flex-row flex-wrap">
      <div class="formulary-heading content-center">
        <div class="mt-2">{{ $t("FormularyPicker.Your") }}</div>
      </div>
      <div>
        <ListBox
          data-testid="formulary-select"
          labelled-by="Formulary Select"
          label="Drug Lists"
          @input="(value) => onFormularySelect(value)"
          :value="getSelectedFormularyValue()"
          :options="getAvailableFormularies()"
        >
          <template
            slot="trigger"
            slot-scope="{ onClick, isOpen, disabled, labelledBy }"
          >
            <div
              :class="{
                'listbox-trigger-button-wrapper': isOpen,
                'listbox-trigger-button-wrapper-closed': !isOpen,
              }"
            >
              <button
                @click="onClick"
                ref="listboxTriggerButton"
                class="leading-normal text-left px-2 py-2 w-full rounded-sm items-center justify-between listbox-trigger-button"
                :class="{
                  'listbox-trigger-button-focus-outline': isOpen,
                  'listbox-trigger-button-disabled': disabled,
                }"
                aria-haspopup="listbox"
                :aria-expanded="isOpen"
                :aria-labelledby="labelledBy"
                :aria-disabled="disabled"
                data-testid="selected-formulary-button"
              >
                <div class="flex flex-row formulary-heading">
                  {{ getSelectedFormularyValue() }}
                  <Icon
                    name="chevron-down"
                    class="listbox-trigger-button-chevron ml-2"
                    :class="{
                      'listbox-trigger-button-chevron-disabled': disabled,
                    }"
                    v-if="!isOpen"
                  />
                  <Icon
                    name="chevron-up"
                    class="listbox-trigger-button-chevron ml-2"
                    v-else
                  />
                </div>
                <div>
                  <hr :class="{ 'hr-underline-is-hidden': isOpen }" />
                </div>
              </button>
            </div>
          </template>
        </ListBox>
      </div>
      <div class="formulary-heading content-center">
        <div class="mt-2">{{ $t("FormularyPicker.DrugList") }}</div>
      </div>
      <div class="my-auto">
        <Modal>
          <template slot="trigger">
            <button class="text-left what-is-a-drug-list-button">
              <Icon name="info-circle" />
              {{ $t("FormularyPicker.WhatIsADrugList") }}
            </button>
          </template>
          <template slot="body">
            <div class="leading-normal text-base">
              <h2 class="font-bold mb-2 coverage-tool-description-link-header">
                {{ $t("Home.WhatIsADrugListModalHeader") }}
              </h2>
              <p
                class="font-normal leading-normal coverage-tool-description-link-body mb-4"
              >
                {{ $t("Home.WhatIsADrugListModalBody") }}
              </p>
              <p
                class="font-normal leading-normal coverage-tool-description-link-body mb-4"
              >
                {{ $t("Home.IdCardInstructions") }}
              </p>
              <img
                src="../assets/images/BTN/IdCard.png"
                :alt="$t('Home.IdCardAlt')"
              />
            </div>
          </template>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import ListBox from "@/components/ListBox/ListBox";
import Icon from "@/components/Icon";
import Modal from "@/components/Modal/Modal";

export default {
  name: "FormularyPicker",
  components: { ListBox, Icon, Modal },
  props: {
    /**
     * List of formulary objects for the formularies to pick from. These objects should have the follow properties:
     * - id
     * - name
     */
    formularies: {
      type: Array,
      required: true,
    },
    selectedFormularyIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      indexOfFormularySelected: undefined,
    };
  },
  mounted() {
    this.getAvailableFormularies();
  },
  methods: {
    getAvailableFormularies() {
      return this.formularies.map(
        (formulary) => formulary.productScheduleDescription
      );
    },
    getSelectedFormularyValue() {
      return this.formularies[this.selectedFormularyIndex]
        .productScheduleDescription;
    },
    onFormularySelect(value) {
      const index = this.formularies.findIndex(
        (formulary) => formulary.productScheduleDescription === value
      );

      this.indexOfFormularySelected = index;
      this.$emit("formulary-selected", { index });
    },
  },
};
</script>

<style scoped>
.formulary-picker {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.listbox-trigger-button-wrapper {
  color: var(--BaseCard-BorderColor);
  font-weight: 300;
}

.listbox-trigger-button-wrapper-closed {
  color: var(--BaseCard-BorderColor);
  font-weight: 300;
}

.listbox-trigger-button-wrapper-closed:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.listbox-trigger-button-chevron {
  color: var(--BaseCard-BorderColor);
  font-size: 16px;
  font-weight: 300;
  margin-top: auto;
  margin-bottom: auto;
}

.formulary-heading {
  font-size: 1.5rem;
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  font-weight: 300;
  font-style: normal;
}

hr {
  background-color: var(--BaseCard-BorderColor);
  border: 0 none;
  height: 1px;
}

.hr-underline-is-hidden {
  background-color: unset;
}

.listbox-trigger-button-wrapper-closed:hover hr {
  visibility: hidden;
}

.listbox-trigger-button:focus hr {
  visibility: hidden;
}

.listbox-trigger-button:focus {
  outline: 0;
  box-shadow: 0 0 0 2px rgba(87, 213, 249, 0.7);
}

.what-is-a-drug-list-button {
  height: 100%;
  color: var(--BaseCard-BorderColor);
  font-size: 14px;
  font-family: Roboto-Regular, sans-serif;
  font-weight: normal;
  line-height: 24px;
  margin-left: 16px;
}

@media screen and (max-width: 630px) {
  .what-is-a-drug-list-button {
    margin-left: 0;
    margin-top: 12px;
  }
}
/deep/ .listbox-selected-option {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  font-weight: bold;
}
</style>
