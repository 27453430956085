<template>
  <Modal class="compare-medicare-plans">
    <template slot="trigger">
      <BaseButton :text="$t('CompareMedicarePlans.ComparePlansButton')" class="w-full font-bold text-xs pl-2 pr-2" />
    </template>
    <template slot="body">
      <MedicareBlueAdvantagePriceDataTable
        v-if="isBlueAdvantageFormularySelected"
        :selectedTier="getTier(medication)"
        :isSelectInsulin="isSelectInsulin(medication)"
      />
      <MedicareBlueAdvantageExtraPriceDataTable v-if="isBlueAdvantageExtraFormularySelected" :selectedTier="getTier(medication)" />
      <MedicareBlueCarePlusPriceDataTable v-if="isBlueCarePlusFormularySelected" :selectedTier="getTier(medication)" />
    </template>
  </Modal>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Modal from "@/components/Modal/Modal";
import BaseButton from "@/components/BaseButton/BaseButton";
import MedicareBlueAdvantagePriceDataTable from "@/components/MedicareBlueAdvantagePriceDataTable";
import MedicareBlueAdvantageExtraPriceDataTable from "@/components/MedicareBlueAdvantageExtraPriceDataTable";
import MedicareBlueCarePlusPriceDataTable from "@/components/MedicareBlueCarePlusPriceDataTable";
import { isBlueAdvantageFormulary, isBlueAdvantageExtraFormulary, isBlueCarePlusFormulary, isMedicareFormulary } from "@/util/isMedicareFormulary";

export default {
  name: "CompareMedicarePlans",
  components: { Modal, BaseButton, MedicareBlueAdvantagePriceDataTable, MedicareBlueAdvantageExtraPriceDataTable, MedicareBlueCarePlusPriceDataTable },
  props: {
    medication: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getTier(medication) {
      return this.getSelectedCoverage(medication)?.coverageTier?.tier;
    },
    isSelectInsulin(medication) {
      return this.hasSelectInsulinCoverageAlert(medication);
    },
    hasSelectInsulinCoverageAlert(medication) {
      return this.getCoverageAlerts(medication)?.some((coverageAlert) => coverageAlert.durServiceDescription === "Select Insulin");
    },
    getCoverageAlerts(medication) {
      return this.getSelectedCoverage(medication)?.coverageAlerts;
    },
    getSelectedCoverage(medication) {
      const selectedDose = this.getSelectedDose(medication);
      const selectedCoverageInfo = this.getSelectedCoverageInfo(medication);

      return selectedCoverageInfo?.coverages.find((coverage) => {
        return coverage.medispanDrugDescriptorId === selectedDose.medispanDrugDescriptorId;
      });
    },
    getSelectedDose(medication) {
      const selectedFormIndex = this.selectedFormIndexes[medication.id];
      const selectedDoseIndex = this.selectedDoseIndexes[medication.id];

      return this.formsAndDoses[medication.id][this.selectedFormularyId][selectedFormIndex].doses[selectedDoseIndex];
    },
    getSelectedCoverageInfo(medication) {
      return medication.coverageInfo.find((coverageInfo) => {
        return coverageInfo.productScheduleId === this.selectedFormularyId;
      });
    },
  },
  computed: {
    ...mapState(["formsAndDoses", "selectedDoseIndexes", "selectedFormIndexes", "selectedFormularyIndex", "isThisPlanYear"]),
    ...mapGetters(["getFormulariesByPlanYear"]),
    formularies() {
      return this.getFormulariesByPlanYear();
    },
    selectedFormularyId() {
      return this.formularies[this.selectedFormularyIndex].productScheduleId;
    },
    selectedFormularyName() {
      return this.formularies[this.selectedFormularyIndex].productScheduleDescription;
    },
    isMedicareFormularySelected() {
      return isMedicareFormulary(this.selectedFormularyName);
    },
    isBlueAdvantageFormularySelected() {
      return isBlueAdvantageFormulary(this.selectedFormularyName);
    },
    isBlueAdvantageExtraFormularySelected() {
      return isBlueAdvantageExtraFormulary(this.selectedFormularyName);
    },
    isBlueCarePlusFormularySelected() {
      return isBlueCarePlusFormulary(this.selectedFormularyName);
    },
  },
};
</script>

<style>
@media screen and (min-width: 800px) {
  .compare-medicare-plans .modal-body {
    width: unset;
  }
}

@media screen and (max-width: 800px) {
  .compare-medicare-plans .DataTable-TableHeader {
    white-space: normal;
  }
}
</style>
