<template>
  <div>
    <Container>
      <div class="p-8 mb-3 rounded-lg search-container">
        <h1 class="font-light mb-2 leading-10 coverage-tool-header">
          {{ $t("Home.Header") }}
        </h1>
        <div class="mb-4 leading-normal font-light coverage-tool-description">
          {{ $t("Home.Description") }}
          <Modal v-if="!StateMachine.current.matches('tutorial')">
            <template slot="trigger">
              <button class="underline text-left what-is-a-drug-list-button">
                {{ $t("Home.WhatIsADrugList") }}
              </button>
            </template>
            <template slot="body">
              <div class="leading-normal text-base">
                <h2 class="font-bold mb-2 coverage-tool-description-link-header">
                  {{ $t("Home.WhatIsADrugListModalHeader") }}
                </h2>
                <p class="font-normal leading-normal coverage-tool-description-link-body mb-4">
                  {{ $t("Home.WhatIsADrugListModalBody") }}
                </p>
                <p class="font-normal leading-normal coverage-tool-description-link-body mb-4">
                  {{ $t("Home.IdCardInstructions") }}
                </p>
                <img src="../assets/images/BTN/IdCard.png" :alt="$t('Home.IdCardAlt')" />
              </div>
            </template>
          </Modal>
        </div>
        <Search
          :placeholder="$t('Search.Placeholder')"
          :no-results-message="$t('Search.NoResultsMessage')"
          @search-element-selected="handleSearchResultSelected"
          class="mb-8"
        />
        <div class="flex flex-col md:flex-row" v-if="!StateMachine.current.matches('tutorial')">
          <div
            class="leading-4 mb-6 md:mb-4 md:pr-8 md:border-r md:flex-1 border-solid add-common-drugs-section"
            v-if="frequentMedications && frequentMedications.length > 0"
            data-testid="frequent-medications"
          >
            <h2 class="text-xs uppercase mb-3 coverage-tool-header-labels">
              {{ $t("Home.AddCommonDrugsLabel") }}
            </h2>
            <button
              v-for="medication in frequentMedications"
              :key="medication.id"
              class="leading-6 rounded-full mr-3 mb-4 px-3 text-sm landing-page-pill"
              @click="handleFrequentMedicationSelected(medication)"
            >
              {{ medication.name }}
            </button>
          </div>
          <div
            class="mb-4"
            :class="{
              'md:ml-8': frequentMedications && frequentMedications.length > 0,
            }"
          >
            <h2 class="leading-4 text-xs uppercase mb-3 coverage-tool-header-labels">
              {{ $t("Home.BrowseByLabel") }}
            </h2>
            <router-link
              to="/browse/drug-name"
              tag="button"
              class="leading-6 rounded-full px-4 text-sm mr-3 mb-4 landing-page-pill"
              data-testid="browse-by-drug-names-button"
            >
              {{ $t("Home.BrowseByDrugNameButton") }}
            </router-link>
            <router-link
              to="/browse/drug-class"
              tag="button"
              class="leading-6 rounded-full px-4 text-sm mb-4 landing-page-pill"
              data-testid="browse-by-drug-class-button"
            >
              {{ $t("Home.BrowseByDrugClassButton") }}
            </router-link>
          </div>
        </div>
        <div class="flex flex-row justify-end w-full" v-if="StateMachine.current.matches('tutorial')">
          <router-link :to="{ name: 'Home' }">
            <button class="text-white underline text-sm font-light skip-tutorial-button">
              {{ $t("Home.SkipTutorial") }}
            </button>
          </router-link>
        </div>
      </div>
      <div class="mb-8">
        <CoverageListManager :medications="coverageListMedications" @formulary-selected="handleFormularySelected" ref="coverageListManager" />
      </div>
      <PageFooter v-if="!StateMachine.current.matches('tutorial')" />
    </Container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CoverageListManager from "@/components/CoverageListManager";
import Search from "@/components/Search";
import Container from "@/components/Container";
import Modal from "@/components/Modal/Modal";
import PageFooter from "@/components/PageFooter";
import { NotificationTypes } from "@/util/NotificationTypes";
import { ToastVariants } from "@/components/Toast";

export default {
  name: "Home",
  components: {
    Container,
    CoverageListManager,
    Search,
    Modal,
    PageFooter,
  },
  props: {
    /**
     * An array of notifications that the prior page may want to alert on when coming to the landing page. This is
     * expected to have a "type" to indicate the type of status. It can also contain a "data" object that can pass any
     * information pertinent to the notification.
     */
    notifications: {
      type: Array,
      default: () => [],
      validator: (value) => {
        return value.every((status) => status.type);
      },
    },
  },
  computed: {
    ...mapState(["coverageListMedications", "frequentMedications", "scrollToIndex"]),
  },
  methods: {
    ...mapActions("Toasts", ["addToast"]),
    ...mapActions(["makeRequestToAddToCoverageList", "makeRequestToGetFrequentMedications"]),
    showNoMedicationCoverageToast(medicationName) {
      this.addToast({
        title: this.$t("Home.NoMedicationCoverageErrorTitle"),
        message: this.$t("Home.NoMedicationCoverageErrorMessage", [medicationName]),
        includeDismissButton: true,
        variant: ToastVariants.ERROR,
      });
    },
    showTutorialCompleteToast() {
      this.addToast({
        title: this.$t("Home.TutorialCompleteToast"),
        includeDismissButton: false,
        isCentered: true,
      });
    },
    handleSearchResultSelected(eventPayload) {
      const { id, name: medicationName } = eventPayload;
      this.makeRequestToAddToCoverageList({ id })
        .then(this.handleAdditionToCoverageList)
        .catch(() => {
          this.showNoMedicationCoverageToast(medicationName);
        });
    },
    handleFrequentMedicationSelected({ id, name: medicationName }) {
      this.makeRequestToAddToCoverageList({ id })
        .then(this.handleAdditionToCoverageList)
        .catch(() => {
          this.showNoMedicationCoverageToast(medicationName);
        });
    },
    handleAdditionToCoverageList(result = {}) {
      this.handleScrollToNewEntry(result);
    },
    handleScrollToNewEntry(addedNewEntry) {
      if (addedNewEntry || this.scrollToIndex !== undefined) {
        if (this.StateMachine.current.matches("tutorial")) {
          this.StateMachine.send("DRUG_SELECTION");
        }
        this.$refs.coverageListManager.scrollToNewEntry();
      }
    },
    handleFormularySelected() {
      this.StateMachine.send("FORMULARY_SELECTION");
    },
    handleNotifications() {
      if (this.notifications && this.notifications.length > 0) {
        this.notifications.forEach((notification) => {
          switch (notification.type) {
            case NotificationTypes.NO_COVERAGE_INFORMATION: {
              this.showNoMedicationCoverageToast(notification.data.medication.name);
              break;
            }
            case NotificationTypes.TUTORIAL_COMPLETE: {
              this.showTutorialCompleteToast();
              break;
            }
          }
        });
      }
    },
  },
  created() {
    this.makeRequestToGetFrequentMedications();
  },
  mounted() {
    this.handleNotifications();
    if (this.scrollToIndex !== undefined && this.scrollToIndex !== null) {
      this.$refs.coverageListManager.scrollToNewEntry();
    }
  },
  inject: ["StateMachine"],
};
</script>

<style scoped>
.coverage-tool-header {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  font-size: 2rem;
  color: var(--LandingPage-SearchContainer-Header-TextColor);
}

.coverage-tool-description {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
  color: var(--LandingPage-SearchContainer-Description-TextColor);
}

.what-is-a-drug-list-button {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}

.coverage-tool-description-link-header {
  color: #1b1b1b;
}

.coverage-tool-description-link-body {
  font-family: Roboto-Regular, sans-serif;
  color: #333333;
}

.coverage-tool-header-labels {
  color: var(--LandingPage-SearchContainer-NavigationSectionHeader-TextColor);
}

.add-common-drugs-section {
  border-color: #004356;
}

.landing-page-pill {
  background-color: var(--LandingPage-SearchContainer-Pill-BackgroundColor);
  color: var(--LandingPage-SearchContainer-Pill-TextColor);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
}

.search-container {
  background: var(--LandingPage-SearchContainer-Background);
  mix-blend-mode: normal;
}

.skip-tutorial-button {
  font-family: "Gotham Rounded SSm A", "Gotham Rounded SSm B", sans-serif;
}
</style>
