<template>
  <div class="ButtonGroup">
    <button
      v-for="button in buttons"
      :key="button.value"
      class="ButtonGroupButton"
      :class="{ 'selected-option': button.value === selectedValue }"
      @click="handleClick(button, selectedValue)"
    >
      {{ button.displayValue }}
    </button>
  </div>
</template>

<script>
export default {
  name: "ButtonGroup",
  props: {
    buttons: {
      type: Array,
      default: () => {},
    },
    selectedValue: {
      type: [Boolean, Number, String],
      required: false,
      default: null,
    },
  },
  methods: {
    handleClick: function (button, previouslySelectedValue) {
      if (button.value !== previouslySelectedValue) {
        this.$emit("selection-changed", button.value);
      }
    },
  },
};
</script>

<style scoped>
.ButtonGroupButton:first-child {
  border-radius: 3px 0 0 3px;
}
.ButtonGroupButton:last-child {
  border-radius: 0 3px 3px 0;
}
.ButtonGroupButton {
  min-height: 2rem;
  padding: 0 0.5rem 0 0.5rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  border-width: 1px;
  background: var(--ButtonGroup-BackgroundColor);
  white-space: nowrap;
  color: var(--ButtonGroup-Color);
  position: relative;
}
.ButtonGroupButton:focus {
  outline: none;
  z-index: 999;
  border-color: white;
}
.selected-option {
  outline: none;
  background: var(--ButtonGroup-BackgroundColor-Selected);
  color: var(--ButtonGroup-Color-Selected);
  border: none;
}
</style>
