var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"formulary-picker"},[_c('div',{staticClass:"flex flex-row flex-wrap"},[_c('div',{staticClass:"formulary-heading content-center"},[_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("FormularyPicker.Your")))])]),_c('div',[_c('ListBox',{attrs:{"data-testid":"formulary-select","labelled-by":"Formulary Select","label":"Drug Lists","value":_vm.getSelectedFormularyValue(),"options":_vm.getAvailableFormularies()},on:{"input":(value) => _vm.onFormularySelect(value)},scopedSlots:_vm._u([{key:"trigger",fn:function({ onClick, isOpen, disabled, labelledBy }){return [_c('div',{class:{
              'listbox-trigger-button-wrapper': isOpen,
              'listbox-trigger-button-wrapper-closed': !isOpen,
            }},[_c('button',{ref:"listboxTriggerButton",staticClass:"leading-normal text-left px-2 py-2 w-full rounded-sm items-center justify-between listbox-trigger-button",class:{
                'listbox-trigger-button-focus-outline': isOpen,
                'listbox-trigger-button-disabled': disabled,
              },attrs:{"aria-haspopup":"listbox","aria-expanded":isOpen,"aria-labelledby":labelledBy,"aria-disabled":disabled,"data-testid":"selected-formulary-button"},on:{"click":onClick}},[_c('div',{staticClass:"flex flex-row formulary-heading"},[_vm._v(" "+_vm._s(_vm.getSelectedFormularyValue())+" "),(!isOpen)?_c('Icon',{staticClass:"listbox-trigger-button-chevron ml-2",class:{
                    'listbox-trigger-button-chevron-disabled': disabled,
                  },attrs:{"name":"chevron-down"}}):_c('Icon',{staticClass:"listbox-trigger-button-chevron ml-2",attrs:{"name":"chevron-up"}})],1),_c('div',[_c('hr',{class:{ 'hr-underline-is-hidden': isOpen }})])])])]}}])})],1),_c('div',{staticClass:"formulary-heading content-center"},[_c('div',{staticClass:"mt-2"},[_vm._v(_vm._s(_vm.$t("FormularyPicker.DrugList")))])]),_c('div',{staticClass:"my-auto"},[_c('Modal',[_c('template',{slot:"trigger"},[_c('button',{staticClass:"text-left what-is-a-drug-list-button"},[_c('Icon',{attrs:{"name":"info-circle"}}),_vm._v(" "+_vm._s(_vm.$t("FormularyPicker.WhatIsADrugList"))+" ")],1)]),_c('template',{slot:"body"},[_c('div',{staticClass:"leading-normal text-base"},[_c('h2',{staticClass:"font-bold mb-2 coverage-tool-description-link-header"},[_vm._v(" "+_vm._s(_vm.$t("Home.WhatIsADrugListModalHeader"))+" ")]),_c('p',{staticClass:"font-normal leading-normal coverage-tool-description-link-body mb-4"},[_vm._v(" "+_vm._s(_vm.$t("Home.WhatIsADrugListModalBody"))+" ")]),_c('p',{staticClass:"font-normal leading-normal coverage-tool-description-link-body mb-4"},[_vm._v(" "+_vm._s(_vm.$t("Home.IdCardInstructions"))+" ")]),_c('img',{attrs:{"src":require("../assets/images/BTN/IdCard.png"),"alt":_vm.$t('Home.IdCardAlt')}})])])],2)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }